.custom-select {
    position: relative;
    width: 50px;
    font-family: Arial, sans-serif;
    z-index:9;
    margin-left:10px;
  }
  
  .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    color:white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-box .arrow {
    transition: transform 0.2s;
    font-size:12px;
    margin-left:5px;
  }
  
  .select-box .arrow.open {
    transform: rotate(180deg);
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 4px;
    background-color: #111;
    color:white;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    font-size: 20px; 
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f0f0f0;
    color:#111;
  }
 
.footer
{
    background: #4a5458;
    color: white;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    z-index:2;
    letter-spacing:4px;
    font-size:16px;
}
.footer a{
    color:#e7a858;
    text-decoration:none;
}
.logos
{
    display:flex;
    align-items: center;
    margin:5px;
}
.logos li
{
    display:flex;
    padding:10px 20px;
}
.logos img
{
   max-height:50px;
   max-width:100%;
}
@media (max-width: 768px) {
   
    .footer
    {
        display:block;
        padding:20px;
        text-align:center;
    }
    .logos
    {
        justify-content:center;
    }
}
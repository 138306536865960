.govbar{
    background:#fff;
    border-bottom:1px solid #cccaca;
    font-family:Arial,sans-serif;
    font-size:12px;
    position:relative;
    z-index:2;
}
.govbar::before,
.govbar::after
{content:'';display:table}
.govbar::after{clear:both}
.govbarlogo{border:0;display:block;float:left;padding:3px 8px}
.govbarlogo img{border:0;display:block;height:40px}
.govbarlinks{display:block;float:right;line-height:30px;list-style:none;margin:0;padding:0}
.govbarlinks li{float:left}
.govbarlink{display:block;float:left;min-height:30px;font-weight:bold;padding:8px}
.govbarlink:link,.govbarlink:visited,.govbarlink:hover,.govbarlink:focus,.govbarlink:active
{border:0;color:#222 !important;text-decoration:none}
.govbarlink:hover,.govbarlink:focus{background:#e6e6e6 !important}
.govbarmore{font-weight:normal}@media only screen and (max-width:44.99em){
    .govbarlogo{float:none}
    .govbarlinks{display:none}}
.block > div
{
     position:relative;
     background-repeat:no-repeat;
     background-size:contain;
}
.blockTitle
{
    text-align:center;
    text-transform:uppercase;
    color:#412619;
    font-size:50px;
    font-weight:300;
    font-style: italic;
    letter-spacing:3px;
}
.blockContent
{
     max-width:1260px;
     margin:auto;
     padding: 50px 10vw 100px;
     margin:-1px auto;
}
.video
{
    width:100vw;
    height:56.2vw;
    display:block;
    background:black;
    transition:height 0.5s ease;
}
.video.fullscreen
{
    width:100vw;
    height:100vh;
    transition:height 0.5s ease;
}


@media (max-width: 1280px) {
   
    .blockContent
    {
        padding: 50px 5vw 100px;
    }
}
@media (max-width: 758px) {
   
    .blockTitle
    {
        font-size:40px;
        letter-spacing:2px;
    }
}
.dialog
{
    background: #F3A645;
    top: 0px;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 9;
    height: 100vh;
    padding:0;
    font-family: 'Myriad Pro', 'DejaVu Sans Condensed', Helvetica, Arial, 'sans-serif';
    font-size:20px;
    overflow-y:scroll;
    color:white;
    left:0;
    background-position: -400px center;
    background-repeat: no-repeat;
    background-size: 800px;
}
.dialog > button
{
    position:fixed;
    left:2vw;
    top:2vw;
    z-index:2;
    background:none;
    border:none;
    cursor:pointer;
}
.dialog > button img
{

    width:48px;
}
.menu
{
    margin:10vw;
}
.menu li
{
    display:block;
    margin-bottom:10vh;
}
.menu li a
{
    color:#412619;
    text-transform:uppercase;
    text-decoration:none;
    font-size:20px;
    letter-spacing:5px;
    display: inline-block;

}
.activeTabIndicator
{
    border-bottom: 2px solid #412619;
    width: calc(100% - 6px);
}
html, body, #root
{
  min-height: 100%;
  font-family: "myriad-pro", sans-serif; 
  font-size:20px;
  margin:0;
  background:#aca393;
  overflow-x:hidden;
  color:#412619;
}
a{
  color:#412619;
}
h1
{
  text-align:center;
}
.background-gradient::before
{
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
}
.center
{
  text-align:center;
}
.index-2
{
  position:relative;
  z-index:1;
}
h1
{
  color: white;
    font-size: 4vw;
    text-transform: uppercase;
    letter-spacing: 2vw;
}
.font-25
{
  font-size:25px;
}
.align-center
{
  text-align:center;
}
.accroche
{
    background: #F3A645;
    color: white;
    font-size: 30px;
    font-weight: bold;
    rotate: -5deg;
    padding: 2px 15px 5px;
    text-wrap: nowrap;
    display: inline-block;
}
.relative
{
  position:relative;
}
.button-light
{
  font-size: 30px;
  font-style: italic;
  border-radius: 30px;
  color: #412619;
  border: #412619 1px solid;
  text-decoration: none;
  padding: 5px 30px 10px;
  background:white;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.button-light:hover
{
    background:#F3A645;
    transition: all 0.5s ease;
}
sup
{
  font-size:18px;
}
.mobile-only
{
    display:none;
}
@media (max-width: 960px) {
   
  .mobile-only
  {
      display:block;
  }
  .desktop-only
  {
      display:none !important;
  }
}
@media (max-width: 768px) {
  h1
  {

    font-size:25px;
     word-wrap:break-word;
     max-width:90%;
     letter-spacing:4px;
  }
}
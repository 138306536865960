.background
{
   position: fixed;
   scale: 1;
   top: 0;
   transition: all 5s ease;
   min-width: 100%;
   height: auto;
 }

.scrolled
{
    scale:1.2;
    transition: all 5s ease;
}

.select
{
    background: none;
    border: none;
    color: white;
    font-size: 20px; 

    margin-left:20px;
}
.selectWrapper
{
    display:flex;
    align-items:center;
    position:absolute;
    right:0;
}
.icon
{
    max-height:20px;
    display: block;
}
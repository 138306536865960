.dialog
{
    background: white;
    top: 0px;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 9;
    height: 100vh;
    padding:0;
    font-family: 'Myriad Pro', 'DejaVu Sans Condensed', Helvetica, Arial, 'sans-serif';
    font-size:20px;
    overflow-y:scroll;
    will-change: auto !important;
}
.dialog > img
{
    position:fixed;
    left:0;
    top:0;
    height:100%;
}
.dialog > button
{
    position:fixed;
    left:2vw;
    top:2vw;
    z-index:2;
    background:none;
    border:none;
    cursor:pointer;
}
.dialog > button img
{

    width:48px;
}
.content
{
    position:relative;
    z-index:2;
    margin-top:;
    text-align: center;
    width:80vw;
    margin:  auto auto;
    border-top:2px solid white;
    border-left:2px solid white;
    padding:2vw 0 0 2vw;
    min-height:20vh;
    max-width: 1260px;
}
.header
{
    color:white;
    background:rgba(32,195,170,0.9);
    padding:1vw;
}
.header h2
{
    margin-top:0;
    margin-bottom:0;
    text-transform:uppercase;
    font-weight:100;
    font-style:italic;
    letter-spacing:5px;
}
.header p{
    margin:0;
}
.arrow
{
    position:relative;
    z-index:3;
}
.qrcontent
{
    background: white;
    padding: 1vw 2vw;
    text-align:left;
}
.qrcontent img
{
   float:left;
   margin-right:20px;
   max-width:100%;
}
.qrcontent:after
{
    content:"";
    display:table;
    clear:both;
}
.contentWrapper
{
    padding-top:80vh;
    position:relative;
    z-index:1;
}
.languageSwitcher
{
    position: fixed;
    right: 2vw;
    top: 2vw;
    z-index:2;
}
.qrcontent iframe
{
    width:100% !important;
    height: 674px !important;
}
@media (min-aspect-ratio: 3/2) {
   
    .dialog > img
    {
        max-width:100%;
        height:auto;
        width:100%;
    }
}
@media (max-width: 768px) {
    .qrcontent iframe
    {
        height: 43vw !important;
    }
    .header h2
    {
        font-size:5vw;
    }
}
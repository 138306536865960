.dialog
{
    background: #20C3AA;
    top: 0px;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 9;
    height: 100vh;
    padding:0;
    font-family: 'Myriad Pro', 'DejaVu Sans Condensed', Helvetica, Arial, 'sans-serif';
    font-size:20px;
    overflow-y:scroll;
    color:white;
    left:0;
    background-position: -400px center;
    background-repeat: no-repeat;
    background-size: 800px;
}
.dialog > img
{
    
}
.dialog > button
{
    position:fixed;
    left:2vw;
    top:2vw;
    z-index:2;
    background:none;
    border:none;
    cursor:pointer;
}
.dialog > button img
{

    width:48px;
}
.content
{
    position:relative;
    z-index:2;
    width:80vw;
    margin:  auto auto;
    padding:2vw 0 0 2vw;
    background:white;
    display:flex;
}
.header
{
     padding:1vw;
}
.header h2
{
    
}
.header p{
    margin:0;
}
.arrow
{
    position:relative;
    z-index:3;
}
.contentWrapper
{
    position:relative;
    z-index:1;
}

.dates
{
    margin-left: 50%;
    background-repeat:repeat-y;
}
.dates li
{
    font-weight:bold;
    font-style:italic;
    display:flex;
    align-items:center;
    padding-left: 7px;
    position: relative;
    margin-left: -66px;
    margin-bottom: 6vh;
}
.dates li p
{
    margin: 2vh 0;
    display: flex;
    align-items: center;
}
.dates li p:before
{
    content:'';
    height:16px;
    width:16px;
    display:block;
    rotate:-45deg;
    border-left:5px solid white;
    border-top:5px solid white;
    margin-right: 10px;
}
.dates li::before
{
    content:"";
    height:37px;
    width:37px;
    border:solid white 5px;
    display:inline-block;
    border-radius:50%;
    margin-right: 20px;
    background:#20C3AA;
}
.current
{
    font-size:30px;
    font-weight:bold;
    background-repeat: no-repeat;
    background-size: 56px;
    background-position: center left;
}
.dates li.inactive
{
    text-decoration: line-through;
    font-weight:100;
}
.dates li.inactive::before
{
    border:solid #D5FDF7 2px;
}
.dates li.current::before
{
    border:none;
    visibility:hidden;
   
}
.dates li.inactive p:before
{
    border-left:1px solid white;
    border-top:1px solid white;
}
.dates li.current p:before
{
    border-left:6px solid white;
    border-top:6px solid white;
    height:22px;
    width:22px;
}
@media (max-width: 768px) {
    .dates
    {
        margin-left: 10vw;
        background-repeat:repeat-y;
    }
}
.loadingWrapper
{
    text-align:center;
    height:100vh;
}
.loadingWrapper > div
{
    top:calc(50% + 6vh);
    left:50%;
    position:absolute;
    transform:translate(-50%,-50%);
    width:10vh;
    height:10vh;
}

.header
{
    display: flex;
    min-height: 10vh;
    width: 80vw;
    margin: auto;
    align-items:center;
    z-index:2;
    position:relative;
}
.header ul li a
{
    text-transform: uppercase;
    text-decoration:none;
    color:white;
}
.logo 
{
    max-height: 6vh;
    margin-left: -3vh;
}

@media (max-width: 1280px) {
   
    .header
    {
        width: 90vw;
    }
}
/* CSS Document */
ul.tabbarmenu 
{
    display: flex;
    align-items: center;
    margin: 1vh 0;
    
}
ul.tabbarmenu li
{
    display: block;
    
}
ul.tabbarmenu li a
{
    padding: 2vh 1rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: all 0.8s ease;
    letter-spacing: 5px;
}
ul.tabbarmenu li a:hover 
{
    opacity:0.4;
}
ul.tabbarmenu li a.active
{
    display: inline-block;
    color: white;
    transition: all 0.8s ease;
}
.activeTabIndicator
{
    border-bottom: 2px solid white;
    width: calc(100% - 6px);
}
@media (max-width: 1280px) {
   
    ul.tabbarmenu li a
    {     
        font-size: 1.8vw;
        padding: 2vh 1vw;
        margin: 0rem 1vw;
    }
}